<template>
  <v-row>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="data"
        :items-per-page="5"
        class="elevation-1"
        :options="options"
        :search="search"
        :custom-filter="filterData"
        :footer-props="{
          'items-per-page-text':this.$t('_common.Rows_per_page')
        }"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            :label="$t('_class_assignments.Search')"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:[`item.percentage`]="{ item }">
          <v-chip
            class= "ma-2"
            small
            :color="getColor(item.percentage)"
            dark
          >
            {{ item.percentage }}%
          </v-chip>
        </template>

      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "UserVideoRateTable",
  data() {
    return {
      role: JSON.parse(localStorage.getItem("userInfo")).userRole,
      userId: JSON.parse(localStorage.getItem("userInfo")).Uid,
      options: {},
      search: "",
      percentage: "",
      data: [],
      headers: [
        {
          text:  this.$t('_analytics._video_rate.User_Name'),
          value: "UserName",
          sortable: true,
        },
        {
          text: this.$t('_analytics._video_rate.Video_Name'),
          value: "ContentName",
          sortable: true,
        },
        {
          text: this.$t('_analytics._video_rate.Video_Percentage'),
          value: "percentage",
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let body = {
        userId: this.userId,
        role: this.role,
      };

      this.$goc.request.post(
        this.$enums.API.GetVideoContentUserList,
        body,
        (response) => {
          this.data = response.Result.VideoUserList.map((element) => {
             if (element.Milestones != null) {
                if (element.IsEnded) {
                  element.percentage = 100;
                } else {
                  // calculate percentage
                  const jsonMilestones = JSON.parse(element.Milestones);
                  const milestoneLength = Object.keys(jsonMilestones).length;
                  const milestoneTrue = Object.values(jsonMilestones).filter((v) => v).length;
                  element.percentage = Math.floor((milestoneTrue / milestoneLength) * 100);
                }
              }else{
                element.percentage= "-";
              }
            return element;
          });
        }
      );
    },

    filterData(value, search) {
      return !search || `${value}`.toLowerCase().indexOf(search.toLowerCase()) > -1;
    },

    getColor (percentage) {
        if (percentage === "-" ) return 'red'
        else if (parseInt(percentage) < 90) return 'orange'
        else return 'green'
      },
  },

};
</script>

<style>
</style>