<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <!-- CARD 1: CONGRATS -->
      <div class="vx-col w-full lg:w-1/3 mb-base">
        <v-alert dense type="info">
          {{ $t("_common.Hello") }}
          <strong>{{ checkpointReward.UserName }}</strong>
        </v-alert>
        <div class="vx-col w-full lg:w-3/3 mb-base">
          <vx-card :title="$t('_analytics.Timeline')">
            <vx-timeline :data="timelineData" />
          </vx-card>
        </div>

        <!-- CARD 5: SUPPORT TRACKER -->
        <div class="vx-col w-full md:w-2/2 lg:w-2/2 xl:w-2/2 mb-base">
          <vx-card :title="this.$t('_analytics.Success_Ratio')">
            <!-- CARD ACTION -->
            <template slot="actions"> </template>

            <div slot="no-body" v-if="true">
              <div class="vx-row text-center">
                <!-- Open Tickets Heading -->
                <div
                  class="
                    vx-col
                    w-full
                    lg:w-1/5
                    md:w-full
                    sm:w-1/5
                    flex flex-col
                    justify-between
                    mb-4
                    lg:order-first
                    md:order-last
                    sm:order-first
                    order-last
                  "
                >
                  <div class="lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6">
                    <h1 class="font-bold text-5xl"></h1>
                    <!--                  <small>Tickets</small>-->
                  </div>
                </div>

                <!-- Chart -->
                <div
                  class="
                    vx-col
                    w-full
                    lg:w-4/5
                    md:w-full
                    sm:w-4/5
                    justify-center
                    mx-auto
                    lg:mt-0
                    md:mt-6
                    sm:mt-0
                    mt-6
                  "
                >
                  <vue-apex-charts
                    type="radialBar"
                    height="385"
                    :options="examChartOptions"
                    :series="exam.series"
                  />
                </div>
              </div>

              <!--             Support Tracker Meta Data -->
              <div class="flex flex-row justify-between px-8 pb-4 mt-4">
                <p class="text-center">
                  <span class="block">{{
                    $t("_analytics.Total_Exam_Count")
                  }}</span>
                  <span class="text-2xl font-semibold">{{
                    totalExamCount
                  }}</span>
                </p>
                <p class="text-center">
                  <span class="block">{{
                    $t("_analytics.Passed_Exam_Count")
                  }}</span>
                  <span class="text-2xl font-semibold">{{
                    passedExamCount
                  }}</span>
                </p>
                <p class="text-center">
                  <span class="block">{{
                    $t("_analytics.Failed_Exam_Count")
                  }}</span>
                  <span class="text-2xl font-semibold">{{
                    failedExamCount
                  }}</span>
                </p>
                <p class="text-center">
                  <span class="block">{{
                    $t("_analytics.Unevaluated_exam_count")
                  }}</span>
                  <span class="text-2xl font-semibold">{{
                    unevaluatedExamCount
                  }}</span>
                </p>
              </div>
            </div>
          </vx-card>
        </div>
      </div>

      <div class="vx-col w-full lg:w-2/3 mb-base">
        <!-- CARD 2: SUBSCRIBERS GAINED -->
        <div class="vx-row">
          <div
            class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-2/4 xl:w-2/4 mb-base"
          >
            <statistics-card-line
              icon="UsersIcon"
              :statistic="totalTopicCount"
              :statisticTitle="this.$t('_analytics.Topic_Count')"
              :chartData="topics.series"
              type="area"
            ></statistics-card-line>
          </div>

          <!-- CARD 3: ORDER RECIEVED -->
          <div
            class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-2/4 xl:w-2/4 mb-base"
          >
            <statistics-card-line
              icon="ShoppingBagIcon"
              :statistic="totalTermCount"
              :statisticTitle="this.$t('_analytics.Term_Count')"
              :chartData="terms.series"
              color="warning"
              type="area"
            ></statistics-card-line>
          </div>
        </div>
        <div class="vx-row">
          <!-- CARD 4: SESSION -->
          <div class="vx-col w-full md:w-2/2 mb-base">
            <vx-card>
              <div
                class="
                  vx-row
                  flex-col-reverse
                  md:flex-col-reverse
                  sm:flex-row
                  lg:flex-row
                "
              >
                <!-- LEFT COL -->
                <!--            <div class="vx-col w-full md:w-full sm:w-1/2 lg:w-1/2 xl:w-1/2 flex flex-col justify-between" v-if="questions.analyticsData">-->
                <div
                  class="
                    vx-col
                    w-full
                    md:w-full
                    sm:w-1/2
                    lg:w-1/2
                    xl:w-1/2
                    flex flex-col
                  "
                >
                  <div>
                    <!--                <h2 class="mb-1 font-bold">{{ questions.analyticsData.session | k_formatter }}</h2>-->
                    <span class="font-medium">{{
                      $t("_analytics.Question_Count")
                    }}</span>

                    <!-- Previous Data Comparison -->
                    <p class="mt-2 text-xl font-medium">
                      <!--                  <span :class="questions.analyticsData.comparison.result >= 0 ? 'text-success' : 'text-danger'">-->
                      <span :class="'text-success'">
                        <!--                    <span v-if="questions.analyticsData.comparison.result > 0">+</span>-->
                        <!--                    <span>{{ questions.analyticsData.comparison.result }}</span>-->
                        <span
                          >{{ $t("_analytics.Total_Count") }}:
                          {{ totalQuestionCount }}</span
                        >
                      </span>
                      <!--                  <span> vs </span>-->
                      <!--                  <span>{{ questions.analyticsData.comparison.str }}</span>-->
                    </p>
                  </div>
                  <vs-button
                    to="/add/question"
                    icon-pack="feather"
                    icon="icon-chevrons-right"
                    icon-after
                    class="shadow-md w-full lg:mt-0 mt-4"
                    >{{ $t("_analytics.Go_To_Add_Question") }}</vs-button
                  >
                </div>

                <!-- RIGHT COL -->
                <div
                  class="
                    vx-col
                    w-full
                    md:w-full
                    sm:w-1/2
                    lg:w-1/2
                    xl:w-1/2
                    flex flex-col
                    lg:mb-0
                    md:mb-base
                    sm:mb-0
                    mb-base
                  "
                >
                  <vue-apex-charts
                    type="bar"
                    height="200"
                    :options="data.chartOptions"
                    :series="data.series"
                    v-if="data.series"
                  />
                </div>
              </div>
              <!-- <vs-divider class="my-6"></vs-divider> -->
              <!--<div class="vx-row">
                <div class="vx-col w-1/2 mb-3">
                  <p>Goal: $100000</p>
                  <vs-progress class="block mt-1" :percent="50" color="primary"></vs-progress>
                </div>
                <div class="vx-col w-1/2 mb-3">
                  <p>Users: 100K</p>
                  <vs-progress class="block mt-1" :percent="60" color="warning"></vs-progress>
                </div>
                <div class="vx-col w-1/2 mb-3">
                  <p>Retention: 90%</p>
                  <vs-progress class="block mt-1" :percent="70" color="danger"></vs-progress>
                </div>
                <div class="vx-col w-1/2 mb-3">
                  <p>Duration: 1yr</p>
                  <vs-progress class="block mt-1" :percent="90" color="success"></vs-progress>
                </div>
              </div>-->
            </vx-card>
          </div>
        </div>
        <div class="vx-col w-full lg:w-3/3 mb-base">
          <vx-card :title="$t('_analytics._video_rate.View_Rate_Video')">
            <UserVideoRateTable />
          </vx-card>
        </div>
      </div>
    </div>

    <div class="vx-row"></div>

    <div class="vx-row">
      <!-- CARD 6: Product Orders -->
      <!--      <div class="vx-col w-full lg:w-1/3 mb-base">-->
      <!--        <vx-card title="Product Orders">-->
      <!--          &lt;!&ndash; CARD ACTION &ndash;&gt;-->
      <!--          <template slot="actions">-->
      <!--            <change-time-duration-dropdown/>-->
      <!--          </template>-->

      <!--          &lt;!&ndash; Chart &ndash;&gt;-->
      <!--          <div slot="no-body">-->
      <!--            <vue-apex-charts type=radialBar height=420 :options="analyticsData.productOrdersRadialBar.chartOptions"-->
      <!--                             :series="productsOrder.series"/>-->
      <!--          </div>-->

      <!--          <ul>-->
      <!--            <li v-for="orderData in productsOrder.analyticsData" :key="orderData.orderType"-->
      <!--                class="flex mb-3 justify-between">-->
      <!--                            <span class="flex items-center">-->
      <!--                                    <span class="inline-block h-4 w-4 rounded-full mr-2 bg-white border-3 border-solid"-->
      <!--                                          :class="`border-${orderData.color}`"></span>-->
      <!--                                    <span class="font-semibold">{{ orderData.orderType }}</span>-->
      <!--                            </span>-->
      <!--              <span>{{ orderData.counts }}</span>-->
      <!--            </li>-->
      <!--          </ul>-->
      <!--        </vx-card>-->
      <!--      </div>-->

      <!-- CARD 7: Sales Stats -->
      <!--      <div class="vx-col w-full lg:w-1/3 mb-base">-->
      <!--        <vx-card title="Sales Stats" subtitle="Last 6 Months">-->
      <!--          <template slot="actions">-->
      <!--            <feather-icon icon="MoreVerticalIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>-->
      <!--          </template>-->
      <!--          <div class="flex">-->
      <!--            <span class="flex items-center"><div-->
      <!--              class="h-3 w-3 rounded-full mr-1 bg-primary"></div><span>Sales</span></span>-->
      <!--            <span class="flex items-center ml-4"><div-->
      <!--              class="h-3 w-3 rounded-full mr-1 bg-success"></div><span>Visits</span></span>-->
      <!--          </div>-->
      <!--          <div slot="no-body-bottom">-->
      <!--            <vue-apex-charts type=radar height=385 :options="analyticsData.statisticsRadar.chartOptions"-->
      <!--                             :series="salesRadar.series"/>-->
      <!--          </div>-->
      <!--        </vx-card>-->
      <!--      </div>-->

      <!-- CARD 8: Activity Timeline -->
      <!-- <div class="vx-col w-full lg:w-1/3 mb-base">
              <vx-card title="Activity Timeline">
                <vx-timeline :data="timelineData"/>
              </vx-card>
      </div>-->
    </div>

    <!--    <div class="vx-row">-->
    <!--      &lt;!&ndash; CARD 9: DISPATCHED ORDERS &ndash;&gt;-->
    <!--      <div class="vx-col w-full">-->
    <!--        <vx-card title="Dispatched Orders">-->
    <!--          <div slot="no-body" class="mt-4">-->
    <!--            <vs-table :data="dispatchedOrders" class="table-dark-inverted">-->
    <!--              <template slot="thead">-->
    <!--                <vs-th>ORDER NO.</vs-th>-->
    <!--                <vs-th>STATUS</vs-th>-->
    <!--                <vs-th>OPERATORS</vs-th>-->
    <!--                <vs-th>LOCATION</vs-th>-->
    <!--                <vs-th>DISTANCE</vs-th>-->
    <!--                <vs-th>START DATE</vs-th>-->
    <!--                <vs-th>EST DELIVERY DATE</vs-th>-->
    <!--              </template>-->

    <!--              <template slot-scope="{data}">-->
    <!--                <vs-tr :key="indextr" v-for="(tr, indextr) in data">-->
    <!--                  <vs-td :data="data[indextr].orderNo">-->
    <!--                    <span>#{{data[indextr].orderNo}}</span>-->
    <!--                  </vs-td>-->
    <!--                  <vs-td :data="data[indextr].status">-->
    <!--                    <span class="flex items-center px-2 py-1 rounded"><div class="h-3 w-3 rounded-full mr-2"-->
    <!--                                                                           :class="'bg-' + data[indextr].statusColor"></div>{{data[indextr].status}}</span>-->
    <!--                  </vs-td>-->
    <!--                  <vs-td :data="data[indextr].orderNo">-->
    <!--                    <ul class="users-liked user-list">-->
    <!--                      <li v-for="(user, userIndex) in data[indextr].usersLiked" :key="userIndex">-->
    <!--                        <vx-tooltip :text="user.name" position="bottom">-->
    <!--                          <vs-avatar :src="user.img" size="30px"-->
    <!--                                     class="border-2 border-white border-solid -m-1"></vs-avatar>-->
    <!--                        </vx-tooltip>-->
    <!--                      </li>-->
    <!--                    </ul>-->
    <!--                  </vs-td>-->
    <!--                  <vs-td :data="data[indextr].orderNo">-->
    <!--                    <span>{{data[indextr].location}}</span>-->
    <!--                  </vs-td>-->
    <!--                  <vs-td :data="data[indextr].orderNo">-->
    <!--                    <span>{{data[indextr].distance}}</span>-->
    <!--                    <vs-progress :percent="data[indextr].distPercent" :color="data[indextr].statusColor"></vs-progress>-->
    <!--                  </vs-td>-->
    <!--                  <vs-td :data="data[indextr].orderNo">-->
    <!--                    <span>{{data[indextr].startDate}}</span>-->
    <!--                  </vs-td>-->
    <!--                  <vs-td :data="data[indextr].orderNo">-->
    <!--                    <span>{{data[indextr].estDelDate}}</span>-->
    <!--                  </vs-td>-->
    <!--                </vs-tr>-->
    <!--              </template>-->
    <!--            </vs-table>-->
    <!--          </div>-->

    <!--        </vx-card>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import analyticsData from "./ui-elements/card/analyticsData.js";
import VxTimeline from "@/components/timeline/VxTimeline";
import UserVideoRateTable from "./components/UserVideoRateTable";

export default {
  data() {
    return {
      checkpointReward: { UserName: "UserName" },
      terms: {},
      topics: {},
      questions: {},
      exam: {},
      totalTermCount: 0,
      totalTopicCount: 0,
      totalQuestionCount: 0,
      totalExamCount: 0,
      passedExamCount: 0,
      failedExamCount: 0,
      unevaluatedExamCount: 0,
      userId: 0,
      data: {},
      examChartOptions: {
        plotOptions: {
          radialBar: {
            size: 158,
            offsetY: -30,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "65%",
            },
            track: {
              background: "rgba(0,0,0,0)",
              strokeWidth: "100%",
            },
            dataLabels: {
              value: {
                offsetY: 30,
                color: "#99a2ac",
                fontSize: "2rem",
              },
            },
          },
        },
        colors: ["#EA5455"],
        fill: {
          type: "gradient",
          gradient: {
            // enabled: true,
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#7367F0"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          dashArray: 8,
        },
        chart: {
          sparkline: {},
        },
        labels: [this.$t("_analytics.Success_Ratio")],
      },
      // productsOrder: {},
      // salesRadar: {},
      timelineData: [],

      analyticsData: analyticsData,
      dispatchedOrders: [],
    };
  },
  components: {
    VueApexCharts,
    StatisticsCardLine,
    VxTimeline,
    UserVideoRateTable,
  },
  mounted: function () {},
  methods: {
    addTopic() {
      this.$router.push("/exam");
    },
  },

  created() {
    console.log(this.$router.options.base);
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.userId = user.Uid;
    this.checkpointReward.UserName = user.FirstName;
    //  User Reward Card

    // Subscribers gained - Statistics
    let chartdata = {
      series: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              this.$goc.console.log(chart, w, e);
            },
          },
        },
        colors: [
          "rgba(115,103,240,0.15)",
          "rgba(115,103,240,0.15)",
          "#7367f0",
          "rgba(115,103,240,0.15)",
          "rgba(115,103,240,0.15)",
          "rgba(115,103,240,0.15)",
        ],
        // plotOptions: {
        //   bar: {
        //     columnWidth: '45%',
        //     distributed: true
        //   }
        // },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
            endingShape: "rounded", // Deprecated
            // borderRadius: '20px', // Coming Soon
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
            style: {
              colors: ["#FFFFFF", "#CCCCCC", "#333333", "#28c76f"],
              fontSize: "12px",
            },
          },
          axisBorder: {
            show: false,
          },

          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
        },
      },
    };

    this.$goc.request.get("/api/Dashboard/GetTermStatistics", (statistics) => {
      if (statistics.Result === undefined) {
        return;
      }
      let data = JSON.parse(statistics.Result);
      this.terms = data;
      this.totalTermCount = data.totalCount;
    });

    // Orders - Statistics
    this.$goc.request.get("/api/Dashboard/GetTopicStatistics", (statistics) => {
      if (statistics.Result === undefined) {
        return;
      }
      let data = JSON.parse(statistics.Result);
      this.topics = data;
      this.totalTopicCount = data.totalCount;
    });

    // Sales bar - Analytics

    this.$goc.request.get(
      "/api/Dashboard/GetQuestionStatistics",
      (statistics) => {
        if (statistics.Result === undefined) {
          return;
        }
        let data = JSON.parse(statistics.Result);
        chartdata.series = data.series;
        chartdata.chartOptions.xaxis.categories = data.categories;
        this.data = chartdata;
        this.totalQuestionCount = data.totalCount;
      }
    );

    //Support Tracker

    this.$goc.request.post(
      "/api/Dashboard/GetExamStatistics",
      { UserId: this.userId },
      (statistics) => {
        if (statistics.Result === undefined) {
          return;
        }
        let data = JSON.parse(statistics.Result);
        this.exam = data;
        this.totalExamCount = data.totalExamCount;
        this.passedExamCount = data.passedExamCount;
        this.failedExamCount = data.failedExamCount;
        this.unevaluatedExamCount = data.unevaluatedExamCount;
      }
    );

    this.$goc.request.get(
      this.$enums.API.TimeLineInfo + this.userId,
      (result) => {
        let timeLineObject = {
          id: 0,
          color: "primary",
          icon: "PlusIcon",
          title: "Title",
          desc: "Description",
          time: "25 mins Ago",
        };
        let newTimeLineArr = result.Result.TimeLineInfoList.map((e) => {
          let color =
            e.Status == "TopicAssigned"
              ? "primary"
              : e.Status == "ExamAssigned"
              ? "warning"
              : e.Status == "ExamFinished"
              ? "success"
              : "danger";
          let desc =
            e.Status == "TopicAssigned"
              ? this.$t("_backendReponse.TopicAssigned")
              : e.Status == "ExamAssigned"
              ? this.$t("_backendReponse.ExamAssigned")
              : e.Status == "ExamFinished"
              ? this.$t("_backendReponse.ExamFinished")
              : "";
          if (e.Type == "Topic" && e.Description !== "") {
            desc = e.Description;
          }
          return {
            id: e.Id,
            color: color,
            icon:
              e.Status == "ExamAssigned"
                ? "Edit2Icon"
                : e.Status == "ExamFinished"
                ? "FlagIcon"
                : e.Type == "Topic"
                ? "FileTextIcon"
                : "MailIcon",
            title: e.Title,
            desc: desc,
            time: this.$moment(e.CreatedDate).format("dddd, MMMM Do YYYY"),
            type: e.Type,
            status: e.Status,
          };
        });
        this.timelineData = newTimeLineArr;
      }
    );

    // Products Order
    /*this.$http.get("/api/card/card-analytics/products-orders")
        .then((response) => {
          this.productsOrder = response.data
        })
        .catch((error) => {
          this.$goc.console.log(error)
        });*/

    // Sales Radar
    /*this.$http.get("/api/card/card-analytics/sales/radar")
        .then((response) => {
          this.salesRadar = response.data
        })
        .catch((error) => {
          this.$goc.console.log(error)
        });*/

    // Dispatched Orders
    /*this.$http.get("/api/table/dispatched-orders")
        .then((response) => {
          this.dispatchedOrders = response.data
        })
        .catch((error) => {
          this.$goc.console.log(error)
        })*/
  },
};
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }

    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}

/*! rtl:end:ignore */
</style>
