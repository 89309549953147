<!-- =========================================================================================
    File Name: VxTimeline.vue
    Description: Timeline Component
    Component Name: VxTimeline
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="timeline-container">
    <ul class="vx-timeline">
      <li v-for="item in data" :key="item.type + '' + item.id">
        <div class="timeline-icon" :class="`bg-${item.color}`">
          <feather-icon
            @click="loadPage(item)"
            :icon="item.icon"
            svgClasses="text-white stroke-current w-5 h-5"
            style="cursor:pointer;"
          />
        </div>
        <div class="timeline-info">
          <p class="font-semibold" style="cursor:pointer; text-decoration:underline;" @click="loadPage(item)">{{ item.title }}</p>
          <span class="activity-desc">{{ item.desc }}</span>
        </div>
        <small class="text-grey activity-e-time">{{ item.time }}</small>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "vx-timeline",
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    loadPage(item) {
      let url = "";
      if (item.type === "Exam" && item.status !== "ExamFinished") {
        url = {
          name: "exam-attend-specific",
          params: { examId: parseInt(item.id) }
        };
      } else if(item.type === "Exam" && item.status === "ExamFinished") {
        url = "/exam/results";
      } else if (item.type === "Topic") {
        url = "/course/view/" + item.id;
      }
      this.$goc.page.load(url);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";
.timeline-container {
  padding-left: 10px !important;
  overflow: auto;
  height: 429px;
}
.vx-timeline li {
  margin-left: 15px !important;
}
</style>
